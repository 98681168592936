import fromEntries from "object.fromentries";


(function polyfills() {
  try {
    // apply shims only for browser
    if (window) {
      if (!Object.fromEntries) fromEntries.shim();
    }

  } catch (e) {
    return null;
  }
})();
